exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-digital-losning-kan-radda-kokaoset-tsx": () => import("./../../../src/pages/news/digital-losning-kan-radda-kokaoset.tsx" /* webpackChunkName: "component---src-pages-news-digital-losning-kan-radda-kokaoset-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-redirect-for-customers-tsx": () => import("./../../../src/pages/redirect-for-customers.tsx" /* webpackChunkName: "component---src-pages-redirect-for-customers-tsx" */),
  "component---src-pages-redirect-for-stores-tsx": () => import("./../../../src/pages/redirect-for-stores.tsx" /* webpackChunkName: "component---src-pages-redirect-for-stores-tsx" */),
  "component---src-pages-se-about-tsx": () => import("./../../../src/pages/se/about.tsx" /* webpackChunkName: "component---src-pages-se-about-tsx" */),
  "component---src-pages-se-index-tsx": () => import("./../../../src/pages/se/index.tsx" /* webpackChunkName: "component---src-pages-se-index-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

